import {ModelError} from "./ModelError";

export class OcularAConstant implements OcularValueNumber
{
    public static readonly minValue = 117
    public static readonly maxValue = 119.90

    private readonly value: number

    public static readonly models: AConstantModel[] = [
        {
            "id": 1,
            "number": 118.97,
            "name": "Alcon Acrysof AU00T0"
        },
        {
            "id": 2,
            "number": 119.08,
            "name": "Alcon Acrysof MA30AC"
        },
        {
            "id": 3,
            "number": 119.4,
            "name": "Alcon Acrysof MA50BM"
        },
        {
            "id": 4,
            "number": 119.2,
            "name": "Alcon Acrysof MA60AC"
        },
        {
            "id": 5,
            "number": 119.2,
            "name": "Alcon Acrysof MA60MA (-D)"
        },
        {
            "id": 6,
            "number": 119.2,
            "name": "Alcon Acrysof MA60MA (+D)"
        },
        {
            "id": 7,
            "number": 119.2,
            "name": "Alcon Acrysof MN60AC"
        },
        {
            "id": 8,
            "number": 119.2,
            "name": "Alcon Acrysof MN60MA (-D)"
        },
        {
            "id": 9,
            "number": 119.2,
            "name": "Alcon Acrysof MN60MA (+D)"
        },
        {
            "id": 10,
            "number": 119.9,
            "name": "Alcon Acrysof MN6AD1"
        },
        {
            "id": 11,
            "number": 118.69,
            "name": "Alcon Acrysof SA60AT"
        },
        {
            "id": 12,
            "number": 118.99,
            "name": "Alcon Acrysof SA60WF"
        },
        {
            "id": 13,
            "number": 118.69,
            "name": "Alcon Acrysof SN60AT"
        },
        {
            "id": 14,
            "number": 118.99,
            "name": "Alcon Acrysof SN60WF"
        },
        {
            "id": 15,
            "number": 119.01,
            "name": "Alcon Acrysof SN6AD1"
        },
        {
            "id": 16,
            "number": 118.97,
            "name": "Alcon Acrysof SN6CWS"
        },
        {
            "id": 17,
            "number": 119.5,
            "name": "Alcon Acrysof SV25T0"
        },
        {
            "id": 18,
            "number": 119.26,
            "name": "Alcon Acrysof TFNT00"
        },
        {
            "id": 19,
            "number": 119.15,
            "name": "Alcon Acrysof Vivity DFT015"
        },
        {
            "id": 20,
            "number": 119.33,
            "name": "Alcon Clareon CNA0T0"
        },
        {
            "id": 21,
            "number": 119.33,
            "name": "Alcon Clareon Mono SY60WF"
        },
        {
            "id": 22,
            "number": 119.1,
            "name": "Alcon Clareon PanOptix CNWTT0"
        },
        {
            "id": 23,
            "number": 119.1,
            "name": "Alcon Clareon PanOptix Toric CNWTTX"
        },
        {
            "id": 27,
            "number": 119.33,
            "name": "Alcon ReStor SN6AD3 +4,00"
        },
        {
            "id": 29,
            "number": 119.08,
            "name": "Alcon Vivity"
        },
        {
            "id": 30,
            "number": 118.3,
            "name": "Bausch & Lomb Loop"
        },
        {
            "id": 31,
            "number": 118.4,
            "name": "Bausch & Lomb Akreos Adapt AO"
        },
        {
            "id": 32,
            "number": 119.12,
            "name": "Bausch & Lomb enVista MX60E"
        },
        {
            "id": 33,
            "number": 118.61,
            "name": "BioTech EyeCryl PC"
        },
        {
            "id": 34,
            "number": 118.502,
            "name": "HOYA 151"
        },
        {
            "id": 35,
            "number": 118.503,
            "name": "HOYA 255"
        },
        {
            "id": 36,
            "number": 118.99,
            "name": "Hoya Gemetric XY1- G (tri)"
        },
        {
            "id": 37,
            "number": 118.99,
            "name": "Hoya Gemetric XY1- GP (tri)"
        },
        {
            "id": 38,
            "number": 118.99,
            "name": "Hoya Gemetric XY1- GPT (tri)"
        },
        {
            "id": 39,
            "number": 118.99,
            "name": "Hoya Gemetric XY1- GT (tri)"
        },
        {
            "id": 40,
            "number": 119.1119,
            "name": "HOYA Nanex NC1-SP"
        },
        {
            "id": 41,
            "number": 118.501,
            "name": "HOYA PC-60R"
        },
        {
            "id": 42,
            "number": 118.601,
            "name": "HOYA PY-60AD"
        },
        {
            "id": 43,
            "number": 119.19,
            "name": "Hoya Vivinex Impress XY1-EM"
        },
        {
            "id": 44,
            "number": 118.8161,
            "name": "HOYA Vivinex Toric XC1-SP"
        },
        {
            "id": 45,
            "number": 119.25,
            "name": "Johnson & Johnson Eyhance ICB00"
        },
        {
            "id": 46,
            "number": 118.7,
            "name": "Johnson & Johnson Sensar AR40e"
        },
        {
            "id": 47,
            "number": 119.05,
            "name": "Johnson & Johnson Symfony ZXR00"
        },
        {
            "id": 48,
            "number": 119.3,
            "name": "Johnson & Johnson Tecnis 1P ZCB00"
        },
        {
            "id": 49,
            "number": 119.11,
            "name": "Johnson & Johnson Tecnis 3P ZA9003"
        },
        {
            "id": 71,
            "number": 118.973,
            "name": "Johnson & Johnson Sensar AAB00"
        },
        {
            "id": 72,
            "number": 119.25,
            "name": "Johnson & Johnson Synergy DFR00V"
        },
        {
            "id": 50,
            "number": 118.83,
            "name": "MediContour Liberty 677MY"
        },
        {
            "id": 51,
            "number": 118.83,
            "name": "MediContour Liberty 677PMY"
        },
        {
            "id": 52,
            "number": 118.9,
            "name": "MediContour Liberty Preloaded 677CMY"
        },
        {
            "id": 53,
            "number": 118.83,
            "name": "MediContour Liberty Toric 677MTY"
        },
        {
            "id": 54,
            "number": 118.828,
            "name": "MediContour Liberty Toric Preloaded 677CMTY"
        },
        {
            "id": 55,
            "number": 117.96,
            "name": "Mediphacos Bios"
        },
        {
            "id": 56,
            "number": 119.16,
            "name": "Mediphacos EasyFlex"
        },
        {
            "id": 57,
            "number": 118,
            "name": "Mediphacos IoFlex"
        },
        {
            "id": 58,
            "number": 119.16,
            "name": "Mediphacos MediFlex"
        },
        {
            "id": 59,
            "number": 119.39,
            "name": "Mediphacos MiniFlex MF-5"
        },
        {
            "id": 60,
            "number": 118.11,
            "name": "Mediphacos MiniFlex MF-R2"
        },
        {
            "id": 61,
            "number": 118.97,
            "name": "Mediphacos MiniFlex Toric"
        },
        {
            "id": 62,
            "number": 118.31,
            "name": "Mediphacos Slim OP-72"
        },
        {
            "id": 63,
            "number": 118.19,
            "name": "Mediphacos UnA"
        },
        {
            "id": 64,
            "number": 118.41,
            "name": "OftVision Oftcryl"
        },
        {
            "id": 65,
            "number": 119.4,
            "name": "PhysIOL 123"
        },
        {
            "id": 66,
            "number": 119.4,
            "name": "PhysIOL Isopure"
        },
        {
            "id": 67,
            "number": 118.6,
            "name": "Rayner RayOne EMV"
        },
        {
            "id": 68,
            "number": 118.34,
            "name": "Zeiss 409M"
        },
        {
            "id": 69,
            "number": 118.5,
            "name": "Zeiss 709M"
        },
        {
            "id": 70,
            "number": 119.72,
            "name": "Zeiss Lucia 621P"
        },
        {
            "id": 73,
            "number": 118.322,
            "name": "Zeiss AT Lara 829MP"
        },
        {
            "id": 74,
            "number": 118.82,
            "name": "Zeiss AT LISA Tri 839MP"
        }
    ]

    constructor(value: number) {
        if (! OcularAConstant.validate(value)) {
            throw new ModelError('Value invalid', 'aconstant-invalid')
        }
        this.value = value
    }

    public static validate(value: number): boolean
    {
        return value >= this.minValue
            && value <= this.maxValue
    }

    public getValue(): number
    {
        return this.value
    }

    public getOffset(): number
    {
        return (0.5829 * this.getValue()) - 68.747
    }

    public getModel(): string
    {
        const model = OcularAConstant.models.find(
            model => model.number === this.getValue()
        )
        return model ? model.name : 'no found'
    }
}

export type AConstantModel = {
    id: number,
    number: number,
    name: string
}


function calculeAConstantOffset(aConstant: number): number
{
    return (0.5829 * aConstant) - 68.747
}

export {
    calculeAConstantOffset
}