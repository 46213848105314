<script setup lang="ts">
import FieldAl from '../fields/FieldAl.vue';
import FieldK from '../fields/FieldK.vue';
import FieldAxis from '../fields/FieldAxis.vue';
import FieldIncision from '../fields/FieldIncision.vue';
import FieldAcd from '../fields/FieldAcd.vue';
import FieldLt from '../fields/FieldLt.vue';
import FieldWtw from '../fields/FieldWtw.vue';
import FieldCheckBox from '../fields/FieldCheckBox.vue';
import { ref, reactive, watch, type PropType, computed } from 'vue';
import type { EyeParameters } from '@/models/EyeParameters';
import { useFormulaFormStore } from '@/stores/formulaFormStore'
import { calculeLt, calculeAxis } from '@/calculators/formulaCalculatorV1'


const formulaFormStore = useFormulaFormStore()

const props = defineProps({
    eyeParameters: {
        type: Object as PropType<EyeParameters>,
        required: false
    },
})

const eyeParameters = reactive({
    acd: props.eyeParameters?.acd ?? 0,
    al: props.eyeParameters?.al ?? 0,
    k: {
        k1: {
            k: props.eyeParameters?.k.k1.k ?? 0,
            axis: props.eyeParameters?.k.k1.axis ?? 0
        },
        k2: {
            k: props.eyeParameters?.k.k2.k ?? 0,
            axis: props.eyeParameters?.k.k2.axis ?? 0
        },
        incision: props.eyeParameters?.k.incision ?? 0
    },
    lt: props.eyeParameters?.lt ?? 0,
    wtw: props.eyeParameters?.wtw ?? 0
} as EyeParameters)

const ltRequired = computed<boolean>(() => {
    return formulaFormStore.formulaForm.patient.year === undefined
        || formulaFormStore.formulaForm.patient.year < 1
})

const generateLtAuto = ref(false)

const emits = defineEmits(['update:eye-parameters'])

watch(props, (newValue) => {
    if(! newValue.eyeParameters) {
        return
    }
    eyeParameters.acd = newValue.eyeParameters.acd
    eyeParameters.al = newValue.eyeParameters.al
    eyeParameters.k.k1.k = newValue.eyeParameters.k.k1.k
    eyeParameters.k.k1.axis = newValue.eyeParameters.k.k1.axis
    eyeParameters.k.k2.k = newValue.eyeParameters.k.k2.k
    eyeParameters.k.k2.axis = newValue.eyeParameters.k.k2.axis
    eyeParameters.k.incision = newValue.eyeParameters.k.incision
    eyeParameters.lt = newValue.eyeParameters.lt
    eyeParameters.wtw = newValue.eyeParameters.wtw
})

const lastUpdateK1Axis = ref(0)
const lastUpdateK2Axis = ref(0)

watch([formulaFormStore.formulaForm, eyeParameters], (values) => {
    if (!generateLtAuto.value || !values[0].patient.year || !values[1].acd) {
        return
    }

    const newLt = calculeLt(values[0].patient.year, values[1].acd)

    eyeParameters.lt = newLt
})

watch(eyeParameters, (newEyeParameters) => {
    if(!Number.isNaN(newEyeParameters.k.k1.axis) && (lastUpdateK2Axis.value == 0 || lastUpdateK2Axis.value == eyeParameters.k.k2.axis)) {
        lastUpdateK1Axis.value = newEyeParameters.k.k1.axis
        newEyeParameters.k.k2.axis = calculeAxis(newEyeParameters.k.k1.axis)
        lastUpdateK2Axis.value = newEyeParameters.k.k2.axis
    }

    if (
        newEyeParameters.acd > 0
        && newEyeParameters.al > 0
        && newEyeParameters.k.k1.k > 0
        && newEyeParameters.k.k2.k > 0
        && newEyeParameters.lt > 0
    ) {
        emits('update:eye-parameters', newEyeParameters)
        return
    }

    emits('update:eye-parameters', undefined)
})

function changeLtAuto(newValue: boolean): void
{
    generateLtAuto.value = newValue

    if(
        newValue
        && formulaFormStore.formulaForm.patient.year
    ) {
        eyeParameters.lt = calculeLt(
            formulaFormStore.formulaForm.patient.year,
            eyeParameters.acd
        )
    }
}

</script>

<template>
    <div class="fields">
        <div class="fields__required">
            <div class="fields__container">
                <FieldAl label="AL" v-model="eyeParameters.al" sufix="mm" :required="true"/>
                <FieldAcd label="ACD" v-model="eyeParameters.acd" sufix="mm" :required="true"/>
            </div>

            <div class="fields__container">
                <FieldK :k-number="1" label="K1" v-model="eyeParameters.k.k1.k" labelDescription="(Flat)" sufix="D" :required="true"/>
                <FieldAxis label="Axis" v-model="eyeParameters.k.k1.axis" sufix="º"/>
            </div>

            <div class="fields__container">
                <FieldK :k-number="2" label="K2" v-model="eyeParameters.k.k2.k" labelDescription="(Step)" sufix="D" :required="true"/>
                <FieldAxis label="Axis" v-model="eyeParameters.k.k2.axis" sufix="º"/>
            </div>
        </div>

        <div class="fields__required">
            <div class="fields__caixa">
                <FieldIncision label="Incision" v-model="eyeParameters.k.incision" sufix="º"/>
            </div>
            
            <div class="fields__container">
                <div class="fields__container-item">
                    <FieldLt label="LT"
                        sufix="mm"
                        v-model="eyeParameters.lt"
                        :required="true"
                        :disabled="generateLtAuto"/>

                    <FieldCheckBox :text="$t('formula.form.lt_select')"
                        :checked="generateLtAuto" 
                        @update:checked="changeLtAuto"/>
                </div>
                <FieldWtw class="fields__container-item"
                    label="WTW" 
                    sufix="mm"
                    v-model="eyeParameters.wtw"/>
            </div>
        </div>
    </div>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"
@use "../../assets/fonts/fonts"

$break-layout: 490px

.fields
  padding-bottom: 5.6em

  &__required
    display: flex
    flex-direction: column
    gap: 2em
    padding-top: 6.0em
    padding-left: 2em
    padding-right: 2em

  &__container
    display: flex
    gap: 2em
    flex-grow: 1

    &-item
        width: 100%

  &__caixa
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 2em

</style>